.App {
    width: 100%;
}

header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

header .left {
    width: 50%;
}

header .right {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 50%;
    padding: 0.6em;
}

.row {
    display: flex;
    flex-direction: row;
}

.wrap {
    flex-wrap: wrap;
}

.row .col {
    display: flex;
    flex-direction: column;
}

a {
    color: var(--blue);
    text-decoration: none;
}

h1 {
    font-family: EvangelionRegular;
    font-weight: 100;
    letter-spacing: 2.5px;
    padding: 0;
    margin: 0.2em;
    font-size: 3.5em;
    color: var(--blue);
}

h2 {
    padding: 0;
    margin: 0.2em;
    font-size: 2.5em;
    color: var(--blue);
}

form {
    margin: 2em;
}

input {
    border: none;
    border-bottom: 1px solid var(--blue);
    background-color: var(--black);
    color: var(--darkyellow);
    outline: none;
    font-family: 'SupplyMono', monospace;
    font-size: 2em;
    width: 10em;
    text-align: center;
    margin-right: 0.2em;
}

button {
    background-color: var(--black);
    border: 1px solid var(--blue);
    border-radius: 3px;
    font-family: 'SupplyMono', monospace;
    color: var(--yellow);
    font-size: 1.4em;
    padding: 0.25em 0.3em 0.05em 0.3em;
}

hr {
    border-bottom: 6px solid var(--darkyellow);
    border-style: solid;
    color: var(--black);
    margin: 2em 0;
}

.Home p {
    margin: 1em 0;
    font-size: 1.6em;
}

.Home ul {
    font-size: 3em;
}

.Home .errata {
    line-height: 1.4em;
}

.Home .errata p {
    font-size: 1em;
}

.Home .errata ul {
    font-size: 1em;
}

.Registry ul {
    font-size: 2em;
}

.Markdown {
    display: block;
    padding: 0.4em;
    margin-top: 0.8em;
    margin-bottom: 1.4em;
    font-size: 1.2em;
    background-color: #2C2E3B;
    border-radius: 5px;
}

.Markdown h1 {
    font-family: SupplyMono;
    font-size: 2em;
    color: var(--darkyellow);
    letter-spacing: unset;
}

.Markdown h2 {
    font-family: SupplyMono;
    font-size: 1.5em;
    color: var(--darkyellow);
}

.AttrRow {
    font-size: 1.8em;
}

.AttrRow .name {
    color: var(--blue);
}

.AttrRow .value {
    color: var(--darkyellow);
}

.imgWrapper {
    margin: 4px;
    border: 4px solid var(--yellow);
    padding: 8px;
    width: 150px;
    height: 150px;
    position: relative;
}

.imgWrapper img {
    width: 150px;
    height: 150px;
    border-radius: 1px;
    opacity: 0.85;
}

.imgWrapper .runnerId {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: var(--yellow);
    text-shadow: 1px 1px 2px var(--black);
    font-size: 1.6em;
}
