:root {
    --black: #000019;
    --blue: #525DDD;
    --yellow: #F2CB04;
    --darkyellow: #C1A203;
}

@font-face {
    font-family: EvangelionRegular;
    src: url('https://files-cors.sirsean.workers.dev/fonts/EVANGELION-k227rn.ttf');
}
@font-face {
    font-family: EvangelionItalic;
    src: url('https://files-cors.sirsean.workers.dev/fonts/EVANGELION-ITALIC-xohlcz.ttf');
}
@font-face {
    font-family: SupplyMono;
    src: url('https://files-cors.sirsean.workers.dev/fonts/PPSupplyMono-Light.ttf');
}

body {
    margin: 0 auto;
    max-width: 800px;
    font-family: SupplyMono, monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--black);
    color: white;
}
